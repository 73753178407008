<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>{{$t('other.myaccount')}}</h1>
            <mdb-container fluid class="clearfix">
              <mdb-row>
                <mdb-col>
                  <mdb-card>
                    <mdb-card-header color="blue darken-3">Informations</mdb-card-header>
                    <mdb-card-body>
                      <div>
                        <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
                        <mdb-alert color="success" v-if="success">{{success}}</mdb-alert>
                        <mdb-input size="sm" :label="$t('other.fullname')" group type="text" v-model="myinformations.fullname" />
                        <mdb-input size="sm" :label="$t('login.your_email')" group type="text" disabled v-model="myinformations.email" />
                        <mdb-input size="sm" :label="$t('other.newpassword')" group type="password" v-model="myinformations.newpassword" />
                        <h3>{{$t('other.language')}}</h3>
                        <mdb-input type="radio" id="option4-1" name="groupOfMaterialRadios" radioValue="fr" v-model="myinformations.lang" label="Français" />
                        <mdb-input type="radio" id="option4-2" name="groupOfMaterialRadios" radioValue="en" v-model="myinformations.lang" label="English" />
                        <br />
                        <hr />
                        <h3>2FA Double Auth.</h3>
                        <mdb-switch onLabel="Activée" offLabel="Désactivée" v-model="verify2faenabled" :disabled="verify2faalreadyenabled" />
                        <div v-if="verify2faenabled && !verify2faalreadyenabled">
                          <br />
                          <h4>Étapes à suivre.</h4>
                          <ol>
                            <li>Installer l'application <a href="https://authy.com/download/" target="_blank">Authy sur votre téléphone cellulaire</a></li>
                            <li><mdb-btn v-on:click="start2faconfigmodal()">Démarrer la configuration</mdb-btn></li>
                          </ol>
                        </div>
                        <br />
                        <hr />
                        <h3>Notifications</h3>
                        <mdb-alert color="info" v-html="$t('other.notifications_notes')"></mdb-alert>
                        <h5 style="line-height: 1.5em;" v-html="$t('other.downloadpushover')"></h5>
                        <mdb-input size="sm" label="Pushover user key" group type="text" v-model="pushoveruserkey" />
                        <hr />
                        <mdb-switch v-model="receivenotificationsbymail" :onLabel="$t('other.receivenotificationsbymail')" offLabel="" />
                        <hr />
                        <mdb-btn color="primary" v-on:click="save()">{{$t('other.save')}}</mdb-btn>
                      </div>
                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
              </mdb-row>
            </mdb-container>
          </mdb-container>
        </div>
      </main>
    </div>
    <mdb-modal :show="start2faconfig" @close="start2faconfig = false" size="md" class="text-center" success>
      <mdb-modal-header center :close="false">
        <p class="heading">Configuration 2FA</p>
      </mdb-modal-header>
      <mdb-modal-body>
        <div v-if="verify2faloading" class="mt-4 mb-4">
          <h3>Initialisation...</h3>
          <mdb-spinner big multicolor />
        </div>
        <div v-else class="mt-4 mb-4">
          <mdb-alert color="danger" v-if="verify2faerror">{{verify2faerror}}</mdb-alert>
          <h4>Scanner ce code QR avec l'application Authy.</h4>
          <br />
          <img :src="`https://chart.googleapis.com/chart?cht=qr&chl=${encodeURIComponent(verify2fatoken.binding.uri)}&chs=180x180&choe=UTF-8&chld=L|2`">
          <br />
          ou saisissez ce code manuellement dans l'application
          <br />
          <mdb-alert color="dark">{{verify2fatoken.binding.secret}}</mdb-alert>
          <hr />
          <h4>Entrez le code fourni par l'application</h4>
          <mdb-input v-model="verify2favalidationcode" label="Code" placeholder="123456" class="px-3 mx-3" />
          <mdb-btn v-on:click="confirm2faauth()">Valider</mdb-btn>
        </div>
      </mdb-modal-body>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbContainer,
  mdbAlert,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbInput,
  mdbRow,
  mdbCol,
  mdbSwitch,
  mdbModal,
  mdbModalHeader,
  mdbSpinner,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'myaccount',
  data() {
    return {
      error: null,
      success: null,
      myinformations: {
        fullname: '',
        email: '',
        newpassword: '',
        lang: 'fr',
      },
      pushoveruserkey: '',
      receivenotificationsbymail: false,
      verify2faenabled: false,
      verify2faalreadyenabled: false,
      start2faconfig: false,
      verify2faloading: true,
      verify2fatoken: {},
      verify2favalidationcode: '',
      verify2faerror: '',
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbBtn,
    mdbContainer,
    mdbAlert,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbSwitch,
    mdbModal,
    mdbModalHeader,
    mdbSpinner,
  },
  mounted() {
    this.myinformations.lang = this.$store.state.currentlang;
    this.loadme();
  },
  methods: {
    loadme() {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/me/getinformations').then((response) => {
        if (response.status === 'OK') {
          self.myinformations.fullname = response.data.user.fullname;
          self.myinformations.email = response.data.user.email;
          self.verify2faenabled = response.data.verify2faenabled;
          self.verify2faalreadyenabled = response.data.verify2faenabled;
          self.pushoveruserkey = response.data.pushoveruserkey;
          self.receivenotificationsbymail = response.data.receivenotificationsbymail;
        } else {
          self.error = response.msg;
        }
      });
    },
    changelang(lang) {
      this.$store.state.currentlang = lang;
      this.$i18n.locale = lang;
      window.localStorage.setItem('currentlang', lang);
    },
    start2faconfigmodal() {
      this.start2faconfig = true;
      this.verify2faloading = true;
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/me/2fa-register').then((response) => {
        if (self.start2faconfig) {
          self.verify2faloading = false;
          self.verify2fatoken = response.data;
        }
      });
    },
    confirm2faauth() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/me/2fa-test-and-confirm', { verify2fatoken: self.verify2fatoken, verify2favalidationcode: self.verify2favalidationcode }).then((response) => {
        if (response.status === 'OK') {
          self.start2faconfig = false;
          self.verify2faalreadyenabled = true;
          self.success = self.$t('other.save_with_success');
          self.$store.state.messages = [];
        } else {
          self.verify2faerror = response.msg;
        }
      });
    },
    save() {
      this.success = null;
      this.error = null;
      this.changelang(this.myinformations.lang);
      const self = this;
      const api = new Apicall();
      this.infos = false;
      api.call('POST', 'api/me/saveinformations', { payload: this.myinformations, pushoveruserkey: this.pushoveruserkey, receivenotificationsbymail: this.receivenotificationsbymail }).then((response) => {
        if (response.status === 'OK') {
          self.success = this.$t('other.save_with_success');
        } else {
          self.error = response.msg;
        }
      });
    },
  },
};
</script>
